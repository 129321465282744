<style>
  header {
    /* background: #f7f7f7; */
    /* background: white; */
    padding: 20px 0 0 0;
    text-shadow: 1px 1px 2px #835300;
  }

  h1 {
    margin: 0;
    text-align: center;
    color: #eee;
  }

  .img-container {
    text-align: center;
  }

  img {
    max-width: 150px;
  }
</style>

<header>
  <div class="img-container">
    <img src="./img/rainbowmaker_logo_tr.png" alt="rainbowmaker" />
  </div>
  <h1>Arcade Games</h1>
</header>
